import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { addHoliday, updateHoliday } from "../../../slices/holidays";
import { useDispatch } from "react-redux";
import Notice from "../../../components/Notice";
import HolidayForm from "./HolidayForm.jsx";
import "../../../components/EmployeeDetails/userDatails/_EmployeeDetails.scss";

function AddNewHolidaySys(props) {
  const { handleClose, id, event, setNoticeDeleteHoliday } = props;

  const dispatch = useDispatch();

  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });

  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);

  const [isUpdated, setUpdated] = useState(true);
  const [fieldChange, setFieldChange] = useState(false);

  const initialValues = {
    name: event ? event?.name : "",
    type: event ? event?.type : "",
    HolidayDate: {
      from: event
        ? {
            day: new Date(event?.startDate).getDate(),
            month: new Date(event?.startDate).getMonth() + 1,
            year: new Date(event?.startDate).getFullYear(),
          }
        : null,
      to: event
        ? {
            day: new Date(event?.endDate).getDate(),
            month: new Date(event?.endDate).getMonth() + 1,
            year: new Date(event?.endDate).getFullYear(),
          }
        : null,
    },
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Holiday Name is Required !"),
    type: Yup.string().required("Holiday Type is Required !"),
  });

  const onSubmit = async (values, submitProps) => {
    const data = {
      name: values.name,
      type: values.type,
      startDate:
        values.HolidayDate?.from?.month +
        "/" +
        values.HolidayDate?.from?.day +
        "/" +
        values.HolidayDate?.from?.year,
      endDate:
        values.HolidayDate?.to?.month +
        "/" +
        values.HolidayDate?.to?.day +
        "/" +
        values.HolidayDate?.to?.year,
    };

    fieldChange && event
      ? await dispatch(updateHoliday({ ...data, id: event?._id })).then(
          (res) => {
            if (res?.error) {
              setNotice({
                text: res?.error?.message,
                open: true,
                state: "error",
              });

              submitProps.setStatus({ success: false });
              submitProps.setSubmitting(true);
            } else {
              if (res?.payload?.message) {
                setNotice({
                  text: res?.payload?.message,
                  open: true,
                  state: "success",
                });
                submitProps.setStatus({ success: true });
                submitProps.setSubmitting(true);

                setTimeout(() => {
                  handleClose(id);
                }, 1500);
              }
            }
          }
        )
      : fieldChange &&
        (await dispatch(addHoliday(data)).then((res) => {
          if (res?.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);

              setTimeout(() => {
                handleClose(id);
              }, 1500);
            }
          }
        }));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        formik.dirty === true && setFieldChange(true);
        return (
          <>
            {isUpdated && (
              <Notice
                open={notice.open}
                text={notice.text}
                state={notice.state}
              />
            )}
            <Form className="add-new-holiday-sys-big-wrapper">
              <HolidayForm
                formik={formik}
                event={event}
                isUpdated={isUpdated}
                id={id}
                setUpdated={setUpdated}
                dispatch={dispatch}
                handleClose={handleClose}
                setNoticeDeleteHoliday={setNoticeDeleteHoliday}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default AddNewHolidaySys;
